import React, { useEffect, useState } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Table } from 'antd';
import dayjs from 'dayjs';
import useUser from 'hook/useUser';
import { getFileFromPath } from 'services/requests/files.request';
import { Positions } from 'services/store/enums/users.enum';
import { IFile } from 'services/store/types/files/Files';
import { IProjectFile } from 'services/store/types/projects/Projects';
import { isUserHasPermission } from 'services/utils/users';

const columns: any[] = [
  {
    dataIndex: 'lp',
    title: 'Lp.',
    align: 'center' as const,
    render: (e: any, r: any, i: number) => i + 1,
  },
  {
    title: 'Nazwa',
    dataIndex: 'name',
    align: 'center' as const,
    defaultSortOrder: 'ascend' as const,
    render: (name: string, r: { isAdmin: boolean }) => name,
    sorter: (a: any, b: any) => a.name.localeCompare(b.name),
  },
  {
    title: 'Opis',
    dataIndex: 'description',
    align: 'center' as const,
    render: (description: string, r: { isAdmin: boolean }) =>
      r.isAdmin ? description : '-',
  },
  {
    title: 'Data',
    dataIndex: 'date',
    align: 'center' as const,
    render: (date: Date, r: { isAdmin: boolean }) =>
      r.isAdmin ? dayjs(date).format('DD.MM.YYYY') : '-',
  },
  {
    title: 'Plik',
    dataIndex: 'getFile',
    align: 'center' as const,
    render: (button: any, r: { isAdmin: boolean; protected: boolean }) =>
      !r.protected || (r.protected && r.isAdmin) ? button : '-',
  },
  {
    title: 'Chroniony',
    dataIndex: 'protected',
    align: 'center' as const,
    render: (pr: boolean) => (pr ? 'TAK' : '-'),
  },
  {
    title: 'Usuń',
    dataIndex: 'remove',
    align: 'center' as const,
    render: (button: any, r: { isAdmin: boolean }) =>
      r.isAdmin ? button : '-',
  },
];

interface IProps {
  files: IProjectFile[];
  handleRemoveFile: (file: IProjectFile) => Promise<any>;
  maxHeight?: number;
}

const FilesList: React.FC<IProps> = ({
  files,
  handleRemoveFile,
  maxHeight,
}) => {
  const { user, isAdmin } = useUser();
  const [list, setList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (files) {
      let newFiles = [...files];
      if (
        !isUserHasPermission(user, [Positions.DESIGNER, Positions.TECHNOLOGIST])
      ) {
        newFiles = newFiles.filter((doc) => !doc.protected);
      }

      newFiles = newFiles.map((file: IProjectFile, i: number) => ({
        ...file,
        key: file._id,
        isAdmin: isAdmin(),
        getFile: (
          <Button
            size="small"
            disabled={!file.file}
            onClick={() => getProjectFile((file.file as IFile).dir)}
          >
            Pobierz
          </Button>
        ),
        remove: isUserHasPermission(user, [
          Positions.DESIGNER,
          Positions.TECHNOLOGIST,
        ]) ? (
          <Popconfirm
            key={1}
            title="Na pewno chcesz usunąć ten plik?"
            onConfirm={() => handleRemoveFile(file)}
            okText="Tak"
            cancelText="Nie"
          >
            <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} />
          </Popconfirm>
        ) : (
          '-'
        ),
      }));
      setList(newFiles);
    }
  }, [files]);

  const getProjectFile = async (path: string) => {
    if (path) {
      setIsLoading(true);
      await getFileFromPath(path);
      setIsLoading(false);
    }
  };

  return (
    <Table
      columns={columns}
      size="small"
      pagination={false}
      dataSource={list}
      loading={isLoading}
      scroll={{ x: 1000, y: maxHeight }}
    />
  );
};

export default FilesList;
