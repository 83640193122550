import { useEffect, useState } from 'react';
import {
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import {
  Descriptions,
  PageHeader,
  Typography,
  Modal,
  message,
  Row,
  Col,
} from 'antd';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import AntDivider from 'components/Divider/Divider';
import { H1 } from 'components/Header';
import ChangeUserDataModal from 'components/Modals/ChangeUserDataModal/ChangeUserData.modal';
import UserPositionTag from 'components/Tags/UserPositionTag/UserPositionTag';
import { useModal } from 'hook/useModals';
import { TAppState } from 'services/store';
import { setLoading } from 'services/store/actions/view';
import { Permissions } from 'services/store/enums/users.enum';
import { IUser } from 'services/store/types/users/Users';
import setAuthToken from 'services/utils/setAuthToken';
import { getUserName } from 'services/utils/string';
import PageTemplate from 'templates/PageTemplate';
import UserActions from './components/UserActions';
import UserChangePositionModal from './components/UserChangePositionModal';

const { Text } = Typography;
const { confirm } = Modal;

const UserPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id }: { id: string } = useParams();
  const [user, setUser] = useState<IUser>();
  const loggedUser = useSelector((state: TAppState) => state.auth.user);
  const { modal, showModal, closeModal } = useModal({
    changePosition: false,
    changeUserData: false,
    sendMessage: false,
  });

  useEffect(() => {
    dispatch(setLoading(true));
    setAuthToken();
    axios.get(`/api/users/${id}`).then((res) => {
      setUser(res.data);
    });
    dispatch(setLoading(false));
  }, []);

  const setUserPermission = async (permission: Permissions) => {
    dispatch(setLoading(true));
    try {
      const res = await axios.patch(`/api/users/${id}`, {
        permission,
        userAction: `Zmienił uprawnienia użytkownika ${
          user ? getUserName(user) : ''
        } na ${permission}`,
      });
      setUser(res.data);
      message.success(`Uprawnienia użytkownika zmienione na: ${permission}`);
    } catch (error) {
      message.error('Błąd');
    }
    dispatch(setLoading(false));
  };

  const handleChangeUserData = async (userData: IUser, actions: any) => {
    dispatch(setLoading(true));
    try {
      const res = await axios.patch(`/api/users/${id}`, {
        ...userData,
        userAction: `Zaktualizował dane innego użytkownika`,
      });
      setUser(res.data);
      message.success('Dane użytkownika zostały zmienione');
      dispatch(setLoading(false));
      closeModal();
    } catch (error: any) {
      if (error.response?.data?.error)
        actions.setErrors(error.response.data.error);
      else message.error('Błąd');
      console.log(error, error.response);
      dispatch(setLoading(false));
    }
  };

  const deleteUser = async () => {
    dispatch(setLoading(true));
    try {
      await axios.delete(`/api/users/${id}`);
      message.success('Użytkownik usunięty');
      history.push('/users');
    } catch (error) {
      console.log(error);
      message.error('Błąd');
    }
    dispatch(setLoading(false));
  };

  const confirmChangeUserPermission = () =>
    confirm({
      title: `Czy chcesz ${
        user?.permission === Permissions.ADMIN ? 'zabrać' : 'nadać'
      } uprawnienia administratora temu użytkownikowi?`,
      icon:
        user?.permission === Permissions.ADMIN ? (
          <MinusCircleOutlined />
        ) : (
          <PlusCircleOutlined />
        ),
      okText: 'Tak',
      okType: user?.permission === Permissions.ADMIN ? 'danger' : 'primary',
      cancelText: 'Nie',
      onOk() {
        setUserPermission(
          user?.permission === Permissions.ADMIN
            ? Permissions.USER
            : Permissions.ADMIN
        );
      },
      onCancel() {},
    });

  const confirmDeleteUser = () =>
    confirm({
      title: 'Czy na pewno chcesz usunąć tego użytkownika?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Tak',
      okType: 'danger',
      cancelText: 'Nie',
      onOk() {
        deleteUser();
      },
      onCancel() {},
    });

  return (
    <PageTemplate>
      {user && (
        <>
          <PageHeader
            title={<H1>{getUserName(user)}</H1>}
            subTitle={user.permission === Permissions.ADMIN && 'Administrator'}
            tags={user.positions.map((position) => (
              <UserPositionTag key={position} position={position} />
            ))}
            extra={
              <UserActions
                user={user}
                loggedUser={loggedUser}
                actions={{
                  showModal,
                  confirmChangeUserPermission,
                  confirmDeleteUser,
                }}
              />
            }
          />
          <Row>
            <Col span={6}>
              <Descriptions title="Dane osobowe" size="small" column={1}>
                <Descriptions.Item label="Imię">
                  <strong>{user.firstName}</strong>
                </Descriptions.Item>
                <Descriptions.Item label="Nazwisko">
                  <strong>{user.lastName}</strong>
                </Descriptions.Item>
                <Descriptions.Item label="E-mail">
                  <strong>
                    <Text copyable>{user.email}</Text>
                  </strong>
                </Descriptions.Item>
                <Descriptions.Item label="Telefon">
                  <strong>
                    <Text copyable>{user.phone}</Text>
                  </strong>
                </Descriptions.Item>
              </Descriptions>
            </Col>
            <Col span={6}>
              <Descriptions title="Inne" size="small" column={1}>
                <Descriptions.Item label="Stawka">
                  <strong>{user.earnings}zł</strong>
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
        </>
      )}
      <AntDivider />

      {/* MODAL */}
      {modal.changePosition && user && (
        <UserChangePositionModal
          closeModal={closeModal}
          user={user}
          setUser={setUser}
        />
      )}
      {modal.changeUserData && user && (
        <ChangeUserDataModal
          closeModal={closeModal}
          user={user}
          handleSubmit={handleChangeUserData}
        />
      )}
    </PageTemplate>
  );
};

export default UserPage;
